<template>
  <main>
    <div class="d-flex justify-content-between">
      <div
        v-for="(item, index) in themes"
        :key="index"
        :class="isActive(item)"
        class="theme-preview"
        @click="selectTheme(item)"
      >
        <div v-if="!index">
          <h5>Default</h5>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around mt-4">
      <b-button variant="primary" @click="applyTheme">APPLY THEME</b-button>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import * as messangerActions from '@/store/modules/messanger/types/actions'
import * as systemNotificationActions from '@/store/modules/system-notification/types/actions'

export default {
  name: 'ThemeSelector',
  mounted () {
    this.onLoad()
  },
  data () {
    return {
      selectedTheme: 'theme-default',
      themes: [
        'theme-default',
        'theme-01',
        'theme-02',
        'theme-03',
        'theme-04',
        'theme-05',
        'theme-06',
        'theme-07'
      ]
    }
  },
  computed: {
    ...mapGetters('messanger', {
      theme: messangerGetters.GET_THEME
    })
  },
  methods: {
    ...mapActions('messanger', {
      changeTheme: messangerActions.CHANGE_THEME
    }),
    ...mapActions('systemNotification', {
      setDoneNotification: systemNotificationActions.SET_DONE_NOTIFICATION
    }),
    isActive (item) {
      const active = this.selectedTheme === item ? 'active' : ''
      return `${item} ${active}`
    },
    selectTheme (item) {
      this.selectedTheme = item
    },
    applyTheme () {
      this.changeTheme(this.selectedTheme)
      this.setDoneNotification(
        {
          text: `You changed the chat theme.`
        }
      )
      this.$bvModal.hide('theme-modal')
    },
    onLoad () {
      this.selectedTheme = this.theme
    }
  }
}
</script>
