<template>
  <div class="d-flex align-items-center">
    <div class="avatar mr-3">
      <img :src="item.avatar" :alt="item.avatar" class="avatar-50 rounded-circle">
      <span class="avatar-status">
        <i class="ri-checkbox-blank-circle-fill" :class="item.isActive ? 'text-success' :'text-warning'"/>
      </span>
    </div>
    <div class="chat-sidebar-name">
      <h6 class="mb-0">{{ item.firstName }} {{ item.lastName }}</h6>
      <div class="d-flex align-items-center">
        <div class="font-20 message-status text-success">
          <i v-if="item.id % 2" class="ri-check-double-line"></i>
          <i v-else class="ri-check-line"></i>
        </div>
        <div class="chat-date ml-2">2/6/2024</div>
      </div>
    </div>
    <div class="chat-meta float-right text-center mt-2 mr-1">
      <b-dropdown id="dropdownMenuButton2" right variant="none remove-toggle">
        <template v-slot:button-content>
          <i class="ri-more-line mr-0" />
        </template>
        <b-dropdown-item href="#"><i class="fa fa-ban mr-0" aria-hidden="true"></i> Block</b-dropdown-item>
        <b-dropdown-item href="#"><i class="fa fa-trash-o mr-0" aria-hidden="true"></i> Delete chat</b-dropdown-item>
      </b-dropdown>
      <!-- <div class="chat-msg-counter bg-primary text-white">20</div> -->
      <!-- <span class="text-nowrap">05 min</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'ChatItem',
  props: {
    item: { type: Object }
  }
}
</script>
