<template>
  <div>
    <b-row>
      <b-col sm="12">
        <iq-card body-class="chat-page p-0" class="mb-0">
          <template v-slot:body>
            <div class="chat-data-block">
              <b-row>
                <b-col lg="3" class="chat-data-left scroller pl-0 pr-0">
                  <div class="chat-search d-flex justify-content-between align-items-center pl-3">
                    <div class="chat-searchbar mt-2 w-85">
                      <div class="form-group chat-search-data m-0">
                        <input type="text" class="form-control round" id="chat-search" placeholder="Search"
                          v-model="search">
                        <i class="ri-search-line" />
                      </div>
                    </div>
                    <div class="mt-2">
                      <b-dropdown id="dropdownMenuButton2" right variant="none remove-toggle">
                        <template v-slot:button-content>
                          <img src="@/assets/images/icon/chat-more-icon.svg" alt="Actions" class="img-fluid" />
                        </template>
                        <b-dropdown-item>
                            <i class="ri-group-line mr-0" aria-hidden="true"></i>
                            Create a group chat
                        </b-dropdown-item>
                        <b-dropdown-item v-b-modal.theme-modal>
                          <i class="ri-gallery-line mr-0" aria-hidden="true"></i>
                          Change theme
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="chat-sidebar-channel scroller mt-1 pl-1">
                    <tab-nav :pills="true" :vertical="true" class="iq-chat-ui" id="chat-list-data">
                      <template v-for="(item, index) in publicList">
                        <tab-nav-items :key="index" :id="`v-pills-${item.id}`" :ariaControls="`v-pills-chat`">
                          <template v-slot:title>
                            <ChatItem :item="item" class="pl-2" />
                          </template>
                        </tab-nav-items>
                      </template>
                    </tab-nav>
                  </div>
                </b-col>

                <div class="col-lg-9 chat-data p-0 chat-data-right border-right">
                  <tab-content id="v-pills-tabContent">
                    <tab-content-item :active="true" id="v-pills-default" aria-labelled-by="v-pills-default">
                      <template>
                        <b-col lg="12">
                          <div class="chat-start">
                            <div class="chat-start">
                              <span class="iq-start-icon text-primary mb-3">
                                <img src="@/assets/images/icon/chat-icon-for-messanger.svg"
                                  alt="Select a chat to start messaging" class="img-fluid" />
                              </span>
                              <h5 id="chat-start" class="mt-4 text-black">Select a chat to start messaging</h5>
                            </div>
                          </div>
                        </b-col>
                      </template>
                    </tab-content-item>
                    <tab-content-item id="v-pills-chat" aria-labelled-by="v-pills-chat">
                      <template>
                        <div class="w-100 d-flex">
                          <div class="chat-window border-right">
                            <div class="chat-head border-left">
                              <header class="d-flex justify-content-between align-items-center bg-white border-bottom p-0">
                                <ToggleButton :mini-toggle="true" :close-button="false"
                                  toggleShow="#chat-user-detail-popup" mediaClass="chat-user-profile">
                                  <!-- <template v-slot:media>
                                  <img :src="checkUser(5, 'image')" alt="avatar" class="avatar-50 rounded-circle cursor-pointer">
                                  <span class="avatar-status"><i class="ri-checkbox-blank-circle-fill text-success" /></span>
                              </template> -->
                                  <template v-slot:body>
                                    <h5 class="font-14 lh-13 mb-0">{{ checkUser(2, 'name') }}</h5>
                                    <div class="font-11 lh-11">6 members</div>
                                    <div class="font-11 lh-11">last seen recently</div>
                                  </template>
                                </ToggleButton>
                                <div class="chat-header-icons d-flex">
                                  <!-- <a class="iq-bg-primary mr-1 chat-icon-phone"><i class="ri-phone-line mr-0" /></a> -->
                                  <!-- <a class="iq-bg-primary mr-1 chat-icon-video"><i class="ri-vidicon-line  mr-0" /></a> -->
                                  <!-- <a class="iq-bg-primary mr-1 chat-icon-delete"><i class="ri-delete-bin-line  mr-0" /></a> -->
                                  <b-dropdown id="dropdownMenuButton2" class="p-0" right variant="none remove-toggle">
                                    <template v-slot:button-content>
                                      <i class="ri-more-2-line mr-0" />
                                    </template>
                                    <b-dropdown-item href="#"><i class="fa fa-ban mr-0" aria-hidden="true"></i>
                                      Block</b-dropdown-item>
                                    <b-dropdown-item href="#"><i class="fa fa-trash-o mr-0" aria-hidden="true"></i>
                                      Delete chat</b-dropdown-item>
                                    <b-dropdown-item href="#"><i class="ri ri-close-line" aria-hidden="true"></i> Clear
                                      history</b-dropdown-item>
                                  </b-dropdown>
                                </div>
                              </header>
                            </div>
                            <div class="d-flex">
                              <div class="chat-content scroller border-left theme" :class="messangerTheme">
                                <template v-for="(item, index) in chat">
                                  <div class="chat chat-left me" :key="index" v-if="!item.me">
                                    <div class="chat-user">
                                      <a class="avatar m-0">
                                        <img :src="checkUser(item.userId, 'image')" alt="avatar"
                                          class="avatar-35 rounded-circle" />
                                      </a>
                                    </div>
                                    <div class="chat-detail">
                                      <div class="chat-message d-flex flex-wrap">
                                        <div class="addon-for-chat-window">
                                          <svg aria-hidden="true" height="12" viewBox="0 0 21 12" width="21"
                                            class="x10l6tqk xng853d xds687c" fill="var(--card-background)"
                                            style="transform: scale(-1, -1) translate(0px);">
                                            <path
                                              d="M20.685.12c-2.229.424-4.278 1.914-6.181 3.403L5.4 10.94c-2.026 2.291-5.434.62-5.4-2.648V.12h20.684z">
                                            </path>
                                          </svg>
                                        </div>
                                        <p>{{ item.text }}</p>
                                        <div class="message-time w-100">
                                          {{ item.time }} AM
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="chat chat-left" :key="index" v-else>
                                    <div class="chat-user">
                                      <a class="avatar m-0">
                                        <img :src="checkUser(item.userId, 'image')" alt="avatar"
                                          class="avatar-35 rounded-circle" />
                                      </a>
                                    </div>
                                    <div class="chat-detail">
                                      <div class="chat-message d-flex flex-wrap">
                                        <div class="addon-for-chat-window">
                                          <svg aria-hidden="true" height="12" viewBox="0 0 21 12" width="21"
                                            class="x10l6tqk xng853d xds687c" fill="var(--card-background)"
                                            style="transform: scale(-1, -1) translate(0px);">
                                            <path
                                              d="M20.685.12c-2.229.424-4.278 1.914-6.181 3.403L5.4 10.94c-2.026 2.291-5.434.62-5.4-2.648V.12h20.684z">
                                            </path>
                                          </svg>
                                        </div>
                                        <p>{{ item.text }}</p>
                                        <div class="message-time w-100 text-success">
                                          {{ item.time }} AM
                                          <span class="font-20">
                                            <i class="ri-check-double-line"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                            <div class="chat-footer border-left pb-1 pt-1 pr-3 pl-3 bg-white border-top">
                              <form class="d-flex align-items-center" action="javascript:void(0);">
                                <div class="chat-attagement d-flex">
                                  <label for="file-attachment">
                                    <div class="cursor-pointer"><img src="@/assets/images/icon/attachment.svg"
                                        alt="attachment" class="img-fluid svg mr-4"></div>
                                  </label>
                                </div>
                                <input id="file-attachment" type="file" name="chat-attachment" class="d-none">
                                <input type="text" v-model="chatMessage" class="form-control font-14 mr-3 border-none"
                                  placeholder="Write a message...">
                                <button :disabled="!chatMessage" type="submit"
                                  class="btn btn-chat-send d-flex align-items-center p-2">
                                  <img src="@/assets/images/chat/send-message.svg" alt="Send" class="img-fluid svg">
                                </button>
                              </form>
                            </div>
                          </div>
                          <div class="emoji-window">
                            <div class="d-flex justify-content-between emoji-holder w-100 flex-wrap">
                              <tab-nav
                                :pills="true"
                                class="iq-chat-ui d-flex justify-content-around align-items-center w-100 pl-3 pr-3 border-bottom"
                                id="chat-emoji-data"
                              >
                                <template>
                                  <tab-nav-items
                                    :id="`v-pills-emoji-id`"
                                    href="#v-pills-emoji"
                                    :ariaControls="`v-pills-emoji`"
                                    :active="true"
                                    role="tab"
                                  >
                                    <template v-slot:title>
                                      Emoji
                                    </template>
                                  </tab-nav-items>
                                  <tab-nav-items :id="`v-pills-stickers-id`" href="#v-pills-stickers"
                                    :ariaControls="`v-pills-stickers`" role="tab">
                                    <template v-slot:title>
                                      Stickers
                                    </template>
                                  </tab-nav-items>
                                  <tab-nav-items :id="`v-pills-gifs-id`" href="#v-pills-gifs"
                                    :ariaControls="`v-pills-gifs`" role="tab">
                                    <template v-slot:title>
                                      Gifs
                                    </template>
                                  </tab-nav-items>
                                </template>
                              </tab-nav>
                              <div class="w-100">
                                <tab-content id="v-pills-tabContent2" class="p-2">
                                  <tab-content-item :active="true" id="v-pills-emoji"
                                    aria-labelled-by="v-pills-emoji">
                                    <template>
                                      EMOJI PACK
                                    </template>
                                  </tab-content-item>
                                  <tab-content-item id="v-pills-stickers" aria-labelled-by="v-pills-emoji">
                                    <template>
                                      STICKERS PACK
                                    </template>
                                  </tab-content-item>
                                  <tab-content-item id="v-pills-gifs" aria-labelled-by="v-pills-emoji">
                                    <template>
                                      GIFS PACK
                                    </template>
                                  </tab-content-item>
                                </tab-content>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </tab-content-item>
                    <tab-content-item id="v-pills-group-chat" aria-labeled-by="v-pills-group-chat">
                      <template>
                        <b-col lg="12">
                          CREATE GROUP CHAT
                        </b-col>
                      </template>
                    </tab-content-item>
                  </tab-content>
                </div>
              </b-row>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="theme-modal" size="lg" title="Select theme" hide-footer content-class="rounded" no-fade>
      <theme-selector />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as messangerGetters from '@/store/modules/messanger/types/getters'
import { socialvue } from '@/config/pluginInit'
import ChatItem from '@/components/socialvue/Chat/ChatItem'
import ToggleButton from '@/components/socialvue/Chat/ToggleButton'
import { Users, MessagesUser1 } from '@/FackApi/api/chat'
import User from '@/Model/User'
import ThemeSelector from '@/components/homeiz/messanger/ThemeSelector.vue'

export default {
  name: 'MessangerPage',
  components: {
    ChatItem,
    ToggleButton,
    ThemeSelector
  },
  mounted () {
    socialvue.index()
  },
  computed: {
    ...mapGetters('messanger', {
      messangerTheme: messangerGetters.GET_THEME
    }),
    filteredList () {
      return this.usersList.filter(item => {
        return item.username.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    publicList () {
      return this.usersList.filter(item => {
        return !item.isPrivate ? item.username.toLowerCase().includes(this.search.toLowerCase()) : false
      })
    },
    privateList () {
      return this.usersList.filter(item => {
        return item.isPrivate ? item.username.toLowerCase().includes(this.search.toLowerCase()) : false
      })
    }
  },
  data () {
    return {
      search: '',
      user: new User({ id: 1, firstName: 'Anna Sthesia', role: 'Developer', avatar: require('@/assets/images/user/user-08.jpg'), isActive: true }),
      usersList: Users,
      chat: MessagesUser1,
      chatMessage: null
    }
  },
  methods: {
    checkUser (item, type) {
      let user = this.usersList.find(user => user.id === item)
      let final
      if (user !== undefined) {
        switch (type) {
          case 'name':
            final = user.firstName
            break
          case 'image':
            final = user.avatar
            break
          case 'role':
            final = user.role
            break
        }
        return final
      }
      return require('@/assets/images/user/user-05.jpg')
    }
  }
}
</script>
<style lang="scss">
.remove-toggle::after {
  content: unset;
}

</style>
